import {
  ComponentCollection,
  Serializer,
  surveyLocalization,
} from "survey-core";
import {
  DefaultFonts,
  QuestionAddedEvent,
  SurveyCreatorModel,
  editorLocalization,
} from "survey-creator-core";
import { initThemeFord } from "../themes/surveyJS/ford";
import { ICustomQuestionTypeConfigurationVOI } from "../interfaces";

export const initSurveyFMC = () => {
  surveyLocalization.supportedLocales = ["en", "es", "fr"];

};

export const initCreatorFMC = (creator: SurveyCreatorModel) => {
  const enLocale = editorLocalization.getLocale("en");
  enLocale.toolboxCategories["__0fmcCagegory"] = "FMC Questions";

  creator.toolbox.changeCategories([
    // { name: "fordvoi", category: "__0fmcCagegory" },
  ]);
};

export const prepareCreatorOnQuestionAddedFMC = (
  sender: SurveyCreatorModel,
  options: QuestionAddedEvent
) => {
  // if (options.question.getType() === "fordvoi") {
  //   console.log("fordvoi question added");
  //   options.question.name = "_voi";
  //   options.question.titleLocation = "hidden";
  // }
};
