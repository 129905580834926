import { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, Timestamp, FirestoreDataConverter, DocumentData, QueryDocumentSnapshot, SnapshotOptions, updateDoc } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';

import auth from '../../services/auth';
import app from '../../services/firebase';

import { ITheme, QuestionRadiogroupModel, Serializer } from "survey-core";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";

import _ from 'lodash';
import { ICreatorOptions } from 'survey-creator-core';

import 'survey-analytics/survey.analytics.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import "../Surveys.css";
import "./admin.css";
import './EditSurvey.css';

import { initCreator, initSurvey, prepareCreatorOnQuestionAdded, prepareForSurvey, prepareSurveyOnQuestionAdded } from '../../helpers/surveyTemplatesAll';
import { initCreatorFord, initSurveyFord, prepareCreatorOnQuestionAddedFord } from '../../helpers/surveyTemplatesFord';
import { initCreatorLincoln, initSurveyLincoln, prepareCreatorOnQuestionAddedLincoln } from '../../helpers/surveyTemplatesLincoln';
import { initCreatorFMC, initSurveyFMC, prepareCreatorOnQuestionAddedFMC } from '../../helpers/surveyTemplatesFMC';

const EEventConverter: FirestoreDataConverter<ExpanseEvent> = {
    toFirestore(event: ExpanseEvent): DocumentData {
        return {
            ...event,
            questions: JSON.stringify(event.questions),
            theme: JSON.stringify(event.theme),
            preRegDate: event.preRegDate ? Timestamp.fromDate(event.preRegDate) : undefined,
            startDate: Timestamp.fromDate(event.startDate),
            endDate: Timestamp.fromDate(event.endDate),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ExpanseEvent {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            name: data.name,
            _preEventID: data._preEventID,
            preRegDate: data.preRegDate?.toDate(),
            startDate: data.startDate.toDate(),
            endDate: data.endDate.toDate(),
            confirmationEmail: data.confirmationEmail,
            reminderEmail: data.reminderEmail,
            thankYouEmail: data.thankYouEmail,
            checkInDisplay: data.checkInDisplay,
            disabled: data.disabled,
            questions: JSON.parse(data.questions),
            theme: JSON.parse(data.theme),
            thanks: data.thanks,
        };
    },
};

initSurveyFMC();
initSurveyFord();
initSurveyLincoln();
initSurvey();

function DashboardScreen() {
    const navigate = useNavigate();
    const params = useParams();

    const [user, userLoading, userError] = useAuthState(auth);
    const [thisEvent, setThisEvent] = useState<ExpanseEvent>();
    const [creator, setCreator] = useState<SurveyCreator>();

    const db = getFirestore(app);
    const eventID: string = params.eventID!;

    useEffect(() => {
        userError && console.error(userError);
    }, [userError]);

    useEffect(() => {
        if (userLoading) return;

        if (!user) {
            return navigate('./login');
        }

        // get the event
        const eventRef = doc(db, "events", eventID).withConverter(EEventConverter);

        getDoc(eventRef).then((event) => {
            const eventData = event.data();
            setThisEvent(eventData);

            const creatorOptions: ICreatorOptions = {
                previewOrientation: "portrait",
                // excluded types: "signaturepad", "file"
                questionTypes: ["boolean", "checkbox", "comment", "dropdown", "tagbox", "expression", "html", "image", "imagepicker", "matrix", "matrixdropdown", "matrixdynamic", "multipletext", "panel", "paneldynamic", "radiogroup", "rating", "ranking", "text"],
                showLogicTab: true,
                isAutoSave: false,
                showSaveButton: true,
                showThemeTab: true,
                showTranslationTab: true,
            };

            const newCreator = new SurveyCreator(creatorOptions);

            initCreatorFMC(newCreator);
            initCreatorFord(newCreator);
            initCreatorLincoln(newCreator);
            initCreator(newCreator);

            newCreator.onSurveyInstanceCreated.add((creator, options) => {
                prepareForSurvey(options.survey);

                // hide options for radiobuttongroup
                if (options.area == "designer-tab") {
                    options.survey.onShowingChoiceItem.add((sender, options) => {
                        if (options.item.locOwner instanceof QuestionRadiogroupModel) {
                            if (options.item.locOwner.renderAs === "radiobuttongroup") {
                                switch (options.item) {
                                    case options.question.newItem:
                                        options.visible = false;
                                        break;
                                    case options.question.noneItem:
                                        options.visible = false;
                                        break;
                                    case options.question.otherItem:
                                        options.visible = false;
                                        break;
                                }
                            }
                        }
                        return;

                        if (options.item == options.question.newItem) {
                            options.visible = false;
                        }
                    });
                }

                if (options.area == "preview-tab") {
                    prepareSurveyOnQuestionAdded(creator, options);
                }
            });

            newCreator.onQuestionAdded.add((sender, options) => {
                prepareCreatorOnQuestionAdded(sender, options);
                prepareCreatorOnQuestionAddedFMC(sender, options);
                prepareCreatorOnQuestionAddedFord(sender, options);
                prepareCreatorOnQuestionAddedLincoln(sender, options);
            });

            // radioGroup
            const radioRenderAsProp = Serializer.getProperty('radiogroup', 'renderAs');
            radioRenderAsProp.visible = true;
            radioRenderAsProp.category = "general";
            radioRenderAsProp.setChoices(["default", "radiobuttongroup"]);

            // Add a custom subitem to the Long Text toolbox item
            const radioGroupItem = newCreator.toolbox.getItemByName("radiogroup");
            radioGroupItem.addSubitem({
                name: "default",
                title: "Radio Group",
                json: {
                    type: "radiogroup",
                }
            });
            radioGroupItem.addSubitem({
                name: "radioButtonGroup",
                title: "Radio Button Group",
                json: {
                    type: "radiogroup",
                    renderAs: "radiobuttongroup",
                }
            });

            newCreator.JSON = eventData?.questions;
            if (eventData?.theme && Object.keys(eventData?.theme).length > 0) {
                newCreator.theme = eventData?.theme as ITheme;
            }

            newCreator.saveSurveyFunc = (saveNo: number, callback: (saveNo: number, success: boolean) => void) => {
                console.log("saving questions...")
                updateDoc(eventRef, {
                    questions: JSON.stringify(newCreator.JSON),
                }).then(() => {
                    console.log("saved!")
                    callback(saveNo, true);
                }).catch((error) => {
                    console.error(error);
                    callback(saveNo, false);
                });
            };

            newCreator.saveThemeFunc = (saveNo: number, callback: (saveNo: number, success: boolean) => void) => {
                console.log("saving theme...")
                updateDoc(eventRef, {
                    theme: JSON.stringify(newCreator.theme),
                }).then(() => {
                    console.log("saved!")
                    callback(saveNo, true);
                }).catch((error) => {
                    console.error(error);
                    callback(saveNo, false);
                });
            };

            setCreator(newCreator);
        });
    }, [userLoading]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <h1>Edit Event {thisEvent?.id}</h1>

            {creator && <SurveyCreatorComponent creator={creator} style={{ flex: 1 }} />}
        </div>
    );
}

export default DashboardScreen;
