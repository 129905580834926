import { SvgRegistry } from "survey-core";

// Function to register all the SVG icons
const registerIcons = async (svgFileNames: string[]) => {
  // Iterate through each SVG file name
  svgFileNames.forEach(async (fileName) => {
    // Skip empty lines
    if (fileName === "") return;

    try {
      // Import the SVG file as a React component
      const SvgIcon = await import(`../assets/icons/sharp-regular/${fileName}.svg`);
  
      // fetch the contents of SvgIcon.default
      const svgString = await fetch(SvgIcon.default).then(res => res.text());
  
      // Extract the icon name from the file name
      const iconName = `icon-${fileName.replace(".svg", "")}`;
  
      // Register the icon with a unique name
      SvgRegistry.registerIconFromSvg(iconName, svgString);
    } catch (error) {
      console.error(`Error registering icon: ${fileName}`, error);
    }  
  });
};

export default registerIcons;
