import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Serializer,
  surveyLocalization,
} from "survey-core";
import {
  DefaultFonts,
  QuestionAddedEvent,
  SurveyCreatorModel,
  editorLocalization,
} from "survey-creator-core";
import registerIcons from "./fontAwesomeIcons";

import { initThemeFord } from "../themes/surveyJS/ford";
import { ICustomQuestionTypeConfigurationVOI } from "../interfaces";

registerIcons([
  "cars",
  "thumbs-up",
  "people-arrows",
  "genderless",
  "calendar-star",
  "money-check-dollar-pen",
  "chart-bar",
  "calendar-clock"
]);

export const initSurveyFord = () => {
  surveyLocalization.supportedLocales = ["en", "es", "fr"];

  Serializer.addProperty("question", {
    name: "_ffs",
    displayName: "FFS question",
    type: "text",
    category: "data",
  });

  ComponentCollection.Instance.add({
    name: "fordvoi",
    title: "Ford VOI",
    iconName: "icon-cars",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.addProperty("fordvoi", {
        name: "onlyInclude",
        displayName: "Only Include these vehicle_ids",
        type: "text",
        category: "general",
      });
    },
    questionJSON: {
      type: "checkbox",
      name: "voi",
      renderAs: "voi",
      maxSelectedChoices: 3,
      choicesByUrl: {
        url: "https://cdn.latitudewebservices.com/vehicles/ford.json",
        valueName: "id",
        titleName: "name",
        image: "image",
      },
    },
    onLoaded(question) {
      this.updateOnlyInclude(question);
    },
    onPropertyChanged(question, propertyName, newValue) {
      if (propertyName === "onlyInclude") {
        this.updateOnlyInclude(question);
      }
    },
    updateOnlyInclude(question) {
      const checkbox = question.contentQuestion;
      if (!!checkbox) {
        checkbox.onlyInclude = question.onlyInclude;
      }
    },
  } as ICustomQuestionTypeConfigurationVOI);

  ComponentCollection.Instance.add({
    name: "fordoptin",
    title: "Ford Opt-In",
    iconName: "icon-thumbs-up",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      "type": "radiogroup",
      "descriptionLocation": "underInput",
      "isRequired": true,
      "renderAs": "radiobuttongroup",
      "choices": [
        {
          "value": 1,
          "text": "Yes"
        },
        {
          "value": 0,
          "text": "No"
        }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "fordrecommend",
    title: "How Likely Recommend Ford",
    iconName: "icon-people-arrows",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("fordrecommend", "name").readOnly = true;
      Serializer.getProperty("fordrecommend", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      isRequired: true,
      choices: [
        {
          value: 1,
          text: {
            default: "Definitely Will NOT Recommend",
            es: "Definitivamente NO recomendaré",
            fr: "Je ne recommanderai certainement PAS",
          },
        },
        {
          value: 2,
          text: {
            default: "Probably Will NOT Recommend",
            es: "Probablemente NO recomendaré",
            fr: "Je ne recommanderai probablement PAS",
          },
        },
        {
          value: 3,
          text: {
            default: "Maybe Will / Maybe Will Not Recommend",
            es: "Tal vez recomendaré / Tal vez no recomendaré",
            fr: "Peut-être que je recommanderai / Peut-être que je ne recommanderai pas",
          },
        },
        {
          value: 4,
          text: {
            default: "Probably Will Recommend",
            es: "Probablemente recomendaré",
            fr: "Je recommanderai probablement",
          },
        },
        {
          value: 5,
          text: {
            default: "Definitely Will Recommend",
            es: "Definitivamente recomendaré",
            fr: "Je recommanderai certainement",
          },
        },
      ],
    },
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "gender",
    title: "Gender",
    iconName: "icon-genderless",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "large",
      choices: [
        { value: "male", text: { default: "Male", es: "Hombre", fr: "Homme" } },
        { value: "female", text: { default: "Female", es: "Mujer", fr: "Femme" } },
        { value: "prefer_not_to_say", text: { default: "Prefer Not To Say", es: "Prefiero no responder", fr: "Je préfère ne pas le dire" } }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "agebracket",
    title: "Age Bracket",
    iconName: "icon-calendar-star",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "large",
      choices: [
        { value: "18 - 24", text: { default: "18 to 24", es: "18 a 24", fr: "18 à 24" } },
        { value: "25 - 34", text: { default: "25 to 34", es: "25 a 34", fr: "25 à 34" } },
        { value: "35 - 44", text: { default: "35 to 44", es: "35 a 44", fr: "35 à 44" } },
        { value: "45 - 54", text: { default: "45 to 54", es: "45 a 54", fr: "45 à 54" } },
        { value: "55 - 64", text: { default: "55 to 64", es: "55 a 64", fr: "55 à 64" } },
        { value: "65+", text: { default: "65 or older", es: "65 o más", fr: "65 ou plus" } }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "howlikelyacquire",
    title: "How Likely to Acquire",
    iconName: "icon-money-check-dollar-pen",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "large",
      choices: [
        { value: "purchase", text: { default: "Purchase", es: "Compra", fr: "Achat" } },
        { value: "lease", text: { default: "Lease", es: "Arrendamiento", fr: "Location" } }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "howlikelypurchasingford",
    title: "How Likely to Purchase Ford",
    iconName: "icon-chart-bar",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "large",
      choices: [
        { value: 1, text: { default: "Definitely Will NOT Consider", es: "Definitivamente NO consideraré", fr: "Je ne considérerai certainement PAS" } },
        { value: 2, text: { default: "Probably Will NOT Consider", es: "Probablemente NO consideraré", fr: "Je ne considérerai probablement PAS" } },
        { value: 3, text: { default: "Maybe Will / Maybe Will NOT Consider", es: "Tal vez consideraré / Tal vez no consideraré", fr: "Peut-être que je considérerai / Peut-être que je ne considérerai pas" } },
        { value: 4, text: { default: "Probably Will Consider", es: "Probablemente consideraré", fr: "Je considérerai probablement" } },
        { value: 5, text: { default: "Definitely Will Consider", es: "Definitivamente consideraré", fr: "Je considérerai certainement" } }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "inmarkettiming",
    title: "In Market Timing",
    iconName: "icon-calendar-clock",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "large",
      choices: [
        { value: "0-30_days", text: { default: "0-30 Days", es: "0-30 días", fr: "0-30 jours" } },
        { value: "1-3_months", text: { default: "1-3 Months", es: "1-3 meses", fr: "1-3 mois" } },
        { value: "4-6_months", text: { default: "4-6 Months", es: "4-6 meses", fr: "4-6 mois" } },
        { value: "7+_months", text: { default: "7+ Months", es: "7+ meses", fr: "7+ mois" } },
        { value: "no_plans", text: { default: "No Definite Plans", es: "Sin planes definidos", fr: "Pas de plans définis" } }
      ]
    }
  } as ICustomQuestionTypeConfiguration);

  DefaultFonts.unshift("FordF1");
};

export const initCreatorFord = (creator: SurveyCreatorModel) => {
  initThemeFord(creator);

  const enLocale = editorLocalization.getLocale("en");
  enLocale.toolboxCategories["__fordCagegory"] = "Ford Questions";

  creator.toolbox.changeCategories([
    { name: "fordvoi", category: "__fordCagegory" },
    { name: "fordoptin", category: "__fordCagegory" },
    { name: "fordrecommend", category: "__fordCagegory" },
    { name: "gender", category: "__fordCagegory" },
    { name: "agebracket", category: "__fordCagegory" },
    { name: "howlikelyacquire", category: "__fordCagegory" },
    { name: "howlikelypurchasingford", category: "__fordCagegory" },
    { name: "inmarkettiming", category: "__fordCagegory" },
  ]);
};

export const prepareCreatorOnQuestionAddedFord = (
  sender: SurveyCreatorModel,
  options: QuestionAddedEvent
) => {
  if (options.question.getType() === "fordvoi") {
    console.log("fordvoi question added");

    options.question.name = "fordVOI";
    options.question._ffs = "voi";

    options.question.locTitle.setJson({
      default:
        "I am interested in receiving more information on the following vehicles.",
      es: "Me interesaría recibir más información sobre los siguientes vehículos.",
      fr: "Je suis intéressé à recevoir plus d'informations sur les véhicules suivants.",
    });

    options.question.locDescription.setJson({
      default: "You may select up to three models.",
      es: "Puede seleccionar hasta tres modelos.",
      fr: "Vous pouvez sélectionner jusqu'à trois modèles.",
    });
  }

  if (options.question.getType() === "fordoptin") {
    console.log("fordoptin question added");
    options.question.name = "fordEmailOptIn";
    options.question._ffs = "emailOptIn";
    options.question.isRequired = true;

    options.question.locTitle.setJson({
      default:
        "Please email me communications including product information, offers, and incentives from Ford Motor Company and the local dealer.",
      es: "Quiero recibir comunicaciones, incluidas información sobre productos y servicios, encuestas, y ofertas especiales de Ford Motor Company y sus concesionarios.",
      fr: "Je souhaite recevoir des communications, y des informations sur les produits et services, des enquêtes, et des offres spéciales de Ford Motor Company et de son concessionnaire.",
    });

    options.question.descriptionLocation = "underInput";
    options.question.locDescription.setJson({
      default:
        "Ford Motor Company respects your privacy and treats your personal information with care. [Click here to read Ford Motor Company's privacy policy.](https://ford.com/help/privacy/)",
      es: "Ford Motor Company respeta su confidencialidad y trata su información personal con respeto. [Haga clic aquí para consultar la política de confidencialidad de Ford Motor Company.](https://es.ford.com/help/privacy/)",
      fr: "Ford Motor Company respecte votre vie privée et traite vos informations personnelles avec soin. [Cliquez ici pour lire la politique de confidentialité de Ford Motor Company.](https://ford.com/help/privacy/)",
    });
  }

  if (options.question.getType() === "fordrecommend") {
    console.log("fordrecommend question added");
    options.question.name = "howLikelyRecommend";
    options.question._ffs = "how_likely_recommend";
    options.question.isRequired = true;
    options.question.buttonSize = "large";

    options.question.locTitle.setJson({
      default: "How likely are you to recommend Ford to a friend, relative or colleague?",
      es: "¿Qué tan probable es que recomiende Ford a un amigo, familiar o colega?",
      fr: "Quelle est la probabilité que vous recommandiez Ford à un ami, un parent ou un collègue?",
    });
  }

  if (options.question.getType() === "gender") {
    console.log("gender question added");
    options.question.name = "gender";
    options.question._ffs = "gender";
    options.question.locTitle.setJson({
      default: "Gende?",
      es: "Sexo",
      fr: "Genre"
    });
  }

  if (options.question.getType() === "agebracket") {
    console.log("age_bracket question added");
    options.question.name = "ageBracket";
    options.question._ffs = "age_bracket";
    options.question.locTitle.setJson({
      default: "May I ask your age?",
      es: "¿Puedo preguntar su edad?",
      fr: "Puis-je vous demander votre âge?"
    });
  }

  if (options.question.getType() === "howlikelyacquire") {
    console.log("how_likely_acquire question added");
    options.question.name = "howLikelyAcquire";
    options.question._ffs = "how_likely_acquire";
    options.question.isRequired = true;

    options.question.locTitle.setJson({
      default: "How do you plan to acquire your next vehicle?",
      es: "¿Cómo piensas adquirir tu próximo vehículo?",
      fr: "Comment prévoyez-vous d'acquérir votre prochain véhicule?"
    });
  }

  if (options.question.getType() === "howlikelypurchasingford") {
    console.log("how_likely_purchasing question added");
    options.question.name = "howLikelyPurchasing";
    options.question._ffs = "how_likely_purchasing_ford";
    options.question.isRequired = true;

    options.question.locTitle.setJson({
      default: "For your next vehicle purchase, how likely would you be to consider purchasing a Ford vehicle?",
      es: "¿Cuál es la probabilidad de que se plantee adquirir un Ford la próxima vez que compre un vehículo?",
      fr: "Pour votre prochain achat de véhicule, quelle est la probabilité que vous envisagiez d'acheter un véhicule Ford?"
    });
  }

  if (options.question.getType() === "inmarkettiming") {
    console.log("in_market_timing question added");
    options.question.name = "inMarketTiming";
    options.question._ffs = "in_market_timing";
    options.question.isRequired = true;

    options.question.locTitle.setJson({
      default: "When do you plan to acquire your next vehicle?",
      es: "¿Cuándo piensas adquirir tu próximo vehículo?",
      fr: "Quand prévoyez-vous d'acheter votre prochain véhicule?"
    });
  }
};