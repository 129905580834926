import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Serializer,
  slk,
  surveyLocalization,
  SurveyModel,
} from "survey-core";
import {
  editorLocalization,
  QuestionAddedEvent,
  SurveyCreatorModel,
  SurveyInstanceCreatedEvent,
} from "survey-creator-core";

import { CheckboxVOIQuestion } from "../surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "../surveysjs_renderers/RadioButtonButton";
import { SurveyBookeoQuestion } from "../surveysjs_renderers/Bookeo";
import { EmailTextInput } from "../surveysjs_renderers/EmailTextInput";

import Showdown from "showdown";
import registerIcons from "./fontAwesomeIcons";

console.log(
  CheckboxVOIQuestion.name,
  RadioGroupRowQuestion.name,
  SurveyBookeoQuestion.name,
  EmailTextInput.name
);

const converter = new Showdown.Converter({
  openLinksInNewWindow: true,
});

registerIcons([
  "person-circle-question",
  "person-circle-question",
  "house-circle-check",
  "at",
  "phone",
]);

export const initSurvey = () => {
  slk(
    "NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5"
  );

  surveyLocalization.supportedLocales = ["en", "es"];

  Serializer.addProperty("question", {
    name: "_ffs",
    displayName: "FFS question",
    type: "text",
    category: "data",
  });

  ComponentCollection.Instance.add({
    name: "firstname",
    title: "First Name",
    iconName: "icon-person-circle-question",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("firstname", "name").readOnly = true;
      Serializer.getProperty("firstname", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "text",
      name: "first_name",
      isRequired: true,
      autocomplete: "given-name",
      validators: [
        {
          type: "text",
          text: {
            default: "Invalid First Name",
            es: "Nombre Inválido",
            fr: "Nom non valide",
          },
          minLength: 2,
        },
        // starts with a letter, can include letters, spaces, and hyphens
        {
          type: "regex",
          text: {
            default: "Invalid First Name",
            es: "Nombre Inválido",
            fr: "Nom non valide",
          },
          regex: "^[A-Za-z][A-Za-z\\s-]*$",
        }
      ],
    },
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "lastname",
    title: "Last Name",
    iconName: "icon-person-circle-question",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("lastname", "name").readOnly = true;
      Serializer.getProperty("lastname", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "text",
      name: "last_name",
      isRequired: true,
      autocomplete: "family-name",
      startWithNewLine: false,
      validators: [
        {
          type: "text",
          text: {
            default: "Invalid Last Name",
            es: "Apellido Inválido",
            fr: "Nom de famille non valide",
          },
          minLength: 2,
        },
        // starts with a letter, can include letters, spaces, and hyphens
        {
          type: "regex",
          text: {
            default: "Invalid Last Name",
            es: "Apellido Inválido",
            fr: "Nom de famille non valide",
          },
          regex: "^[A-Za-z][A-Za-z\\s-]*$",
        }
      ],
    },
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "autocompleteaddress",
    title: "Google Autocomplete Address",
    iconName: "icon-house-circle-check",
    showInToolbox: true,
    onInit: () => {
      Serializer.getProperty("autocompleteaddress", "name").readOnly = true;
      Serializer.getProperty("autocompleteaddress", "_ffs").readOnly = true;
    },
    elementsJSON: [
      {
        type: "text",
        name: "address1",
        title: {
          default: "Street Address",
          es: "Dirección 1",
          fr: "Adresse",
        },
        isRequired: true,
        autocomplete: "address-line1",
        addressAutocompleteConfig: {
          addressPartMap: {
            address1: "address1",
            address2: "address2",
            city: "city",
            state: "state",
            zip: "zip",
          },
        },
        validators: [
          {
            type: "text",
            text: {
              default: "Invalid Address",
              es: "Dirección Inválida",
              fr: "Adresse non valide",
            },
            minLength: 5,
          },
        ],
      },
      {
        type: "text",
        name: "address2",
        startWithNewLine: false,
        title: {
          default: "Apt/Suite/Other",
          es: "Apto/Suite/Otro",
          fr: "Appartement/Suite/Autre",
        },
        autocomplete: "address-line2",
      },
      {
        type: "text",
        name: "city",
        title: {
          default: "City",
          es: "Ciudad",
          fr: "Ville",
        },
        isRequired: true,
        validators: [
          {
            type: "text",
            text: {
              default: "Invalid City",
              es: "Ciudad Inválida",
              fr: "Ville non valide",
            },
            minLength: 2,
          },
          {
            type: "regex",
            text: {
              default: "Invalid City",
              es: "Ciudad Inválida",
              fr: "Ville non valide",
            },
            regex: "^[A-Za-z][A-Za-z\\s-]*$",
          }
        ],
      },
      {
        type: "text",
        name: "state",
        isRequired: true,
        startWithNewLine: false,
        title: {
          default: "State",
          es: "Provincia",
          fr: "Province",
        },
        maxLength: 2,
        validators: [
          {
            type: "text",
            text: {
              default: "Invalid State",
              es: "Provincia Inválida",
              fr: "Province non valide",

            },
            minLength: 2,
          },
          {
            type: "regex",
            text: {
              default: "Invalid State",
              es: "Provincia Inválida",
              fr: "Province non valide",

            },
            regex: "^[A-Za-z][A-Za-z\\s-]*$",
          }
        ],
      },
      {
        type: "text",
        name: "zip",
        isRequired: true,
        valueName: "zip_code",
        maxLength: "6",
        startWithNewLine: false,
        title: {
          default: "Zip Code",
          es: "Código Postal",
          fr: "Code Postal",
        },
        autocomplete: "postal-code",
        validators: [
          {
            type: "text",
            text: {
              default: "Invalid Zip",
              es: "Código Postal Inválido",
              fr: "Code Postal Non Valide",

            },
            minLength: 5,
          },
          // need regex that will match US zip codes and Canadian postal codes
          {
            type: "regex",
            text: {
              default: "Invalid Zip",
              es: "Código Postal Inválido",
              fr: "Code Postal Non Valide",

            },
            regex: "^[0-9]{5}(?:-[0-9]{4})?$|^[A-Za-z][0-9][A-Za-z][\\s\\-]?[0-9][A-Za-z][0-9]$",
          }
        ],
      },
      {
        // this is a hidden question that is used to store the country from google autocomplete
        type: "text",
        name: "country",
        title: {
          default: "Country",
          es: "País",
          fr: "Pays",
        },
        visible: false,
        isRequired: false,
        valueName: "country",
      },
    ],
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "email",
    title: "Email Address",
    iconName: "icon-at",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("email", "name").readOnly = true;
      Serializer.getProperty("email", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "emailtextinput",
      // type: "text",
      // renderAs: "emailtextinput",
      name: "email",
      inputType: "email",
      autocomplete: "email",
      validators: [
        {
          "type": "email"
        },
        {
          "type": "expression",
          "text": {
            default: "Invalid Email Address",
            es: "Correo electrónico Inválido",
            fr: "Email non valide",
          },
          "expression": "validateEmail({email})"
        }
      ],
    },
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "phone",
    title: "Phone Number",
    iconName: "icon-phone",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("phone", "name").readOnly = true;
      Serializer.getProperty("phone", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "text",
      name: "phone",
      inputType: "tel",
      autocomplete: "tel",
      inputMask: "phone",
      inputFormat: "999-999-9999",
    },
  } as ICustomQuestionTypeConfiguration);

  ComponentCollection.Instance.add({
    name: "optin",
    title: "Check Opt-In",
    iconName: "icon-checkbox",
    showInToolbox: true,
    inheritBaseProps: true,
    questionJSON: {
      type: "boolean",
      renderAs: "checkbox",
      label: "I agree",
      titleLocation: "hidden",
      valueTrue: "Yes",
      valueFalse: "No",
    },
  } as ICustomQuestionTypeConfiguration);
};

export const initCreator = (creator: SurveyCreatorModel) => {
  creator.toolbox.showCategoryTitles = true;
  creator.toolbox.forceCompact = false;
  creator.toolbox.canCollapseCategories = true;
  creator.toolbox.allowExpandMultipleCategories = true;
  creator.toolbox.keepAllCategoriesExpanded = false;

  const themeTabPlugin = creator.themeEditor;

  // sort the themes so that the custom themes starting with __ are at the top
  themeTabPlugin.availableThemes = themeTabPlugin.availableThemes.sort(
    (a: string, b: string) => {
      if (a.startsWith("__") && !b.startsWith("__")) {
        return -1;
      } else if (!a.startsWith("__") && b.startsWith("__")) {
        return 1;
      } else if (a.startsWith("__") && b.startsWith("__")) {
        return a.localeCompare(b); // Sort alphabetically if both start with __
      } else {
        return a.localeCompare(b); // Sort alphabetically if neither starts with __
      }
    }
  );

  const enLocale = editorLocalization.getLocale("en");
  enLocale.toolboxCategories["__0pii"] = "Personal Information Questions";

  creator.toolbox.changeCategories([
    { name: "optin", category: "choice" },
    { name: "autocompleteaddress", category: "__0pii" },
    { name: "firstname", category: "__0pii" },
    { name: "lastname", category: "__0pii" },
    { name: "email", category: "__0pii" },
    { name: "phone", category: "__0pii" },
  ]);

  // sort the toolbox categories so that the custom categories starting with __ are at the top
  creator.toolbox.categories = creator.toolbox.categories.sort(
    (a: any, b: any) => {
      if (a.name.startsWith("__") && !b.name.startsWith("__")) {
        return -1;
      } else if (!a.name.startsWith("__") && b.name.startsWith("__")) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    }
  );

  // open just the first category
  creator.toolbox.collapseAllCategories();
  creator.toolbox.expandCategory("__0pii");
};

export const prepareForSurvey = (thisSurvey: SurveyModel) => {
  // add custom css classes
  thisSurvey.onUpdateQuestionCssClasses.add((survey, options) => {
    if (options.question.name === "signature") {
      options.cssClasses.root += " signatureInput";
    }
  });

  // add markdown support
  thisSurvey.onTextMarkdown.add(function (survey, options) {
    //convert the mardown text to html
    var str = converter.makeHtml(options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    options.html = str;
  });
};

export const prepareSurveyOnQuestionAdded = (
  creator: SurveyCreatorModel,
  options: SurveyInstanceCreatedEvent
) => {
  options.survey.onAfterRenderQuestionInput.add((survey, questionOptions) => {
    if (questionOptions.question.name === "address1") {
      const autocomplete = new google.maps.places.Autocomplete(
        questionOptions.htmlElement as HTMLInputElement,
        {
          types: ["address"],
          componentRestrictions: {
            country: ["us"],
          },
          fields: ["address_components", "formatted_address"],
          ...questionOptions.question.addressAutocompleteConfig,
        }
      );

      autocomplete.addListener("place_changed", async function () {
        console.log("place_changed");
        const place = await autocomplete.getPlace();

        const ParsedData: Record<string, any> = {
          formatted_address: place.formatted_address,
        };

        const postalData = place.address_components?.find((item) =>
          item.types.includes("postal_code")
        );
        const countryData = place.address_components?.find((item) =>
          item.types.includes("country")
        );
        const addressData = place.address_components?.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        const cityData = place.address_components?.find((item) =>
          item.types.includes("locality")
        );
        const routeData = place.address_components?.find((item) =>
          item.types.includes("route")
        );
        const streetNumberData = place.address_components?.find((item) =>
          item.types.includes("street_number")
        );

        ParsedData.address1 = [
          streetNumberData?.long_name,
          routeData?.long_name,
        ]
          .join(" ")
          .trim();
        ParsedData.city = cityData == null ? "" : cityData.long_name;
        ParsedData.state = addressData == null ? "" : addressData.short_name;
        ParsedData.zip_code = postalData == null ? "" : postalData.long_name;
        ParsedData.country = countryData == null ? "" : countryData.short_name;

        const isComposite = survey.getQuestionByName("_addressGroup");
        if (isComposite) {
          survey.setValue("_addressGroup", ParsedData);
        } else {
          [
            "_addressGroup.address1",
            "_addressGroup.city",
            "_addressGroup.state",
            "_addressGroup.zip_code",
            "_addressGroup.country",
          ].forEach((key) => {
            try {
              survey.setValue(key, ParsedData[key], true, true);
            } catch (e) {
              console.log("error", e);
            }
          });
        }
      });
    }
  });
};

export const prepareCreatorOnQuestionAdded = (
  sender: SurveyCreatorModel,
  options: QuestionAddedEvent
) => {
  console.log('prepareCreatorOnQuestionAdded', options.question.getType());
  if (options.question.getType() === "optin") {
    options.question.titleLocation = "hidden";
    options.question.descriptionLocation = "underInput";
    options.question.label = "I agree";
  }

  if (options.question.getType() === "autocompleteaddress") {
    console.log("autocompleteaddress question added");
    options.question.name = "address_group";
    options.question._ffs = "address_group";
    options.question.titleLocation = "hidden";
  }

  if (options.question.getType() === "firstname") {
    console.log("firstname question added");
    options.question.name = "first_name";
    options.question._ffs = "first_name";

    options.question.locTitle.setJson({
      default: "First Name",
      es: "Nombre",
      fr: "Prénom",
    });
  }

  if (options.question.getType() === "lastname") {
    console.log("lastname question added");
    options.question.name = "last_name";
    options.question._ffs = "last_name";

    options.question.locTitle.setJson({
      default: "Last Name",
      es: "Apellidos",
      fr: "Nom de famille",
    });
  }

  if (options.question.getType() === "email") {
    console.log("email question added");
    options.question.name = "email";
    options.question._ffs = "email";

    options.question.locTitle.setJson({
      default: "Email Address",
      es: "Correo electrónico",
      fr: "Email",
    });
  }

  if (options.question.getType() === "phone") {
    console.log("phone question added");
    options.question.name = "phone";
    options.question._ffs = "phone";

    options.question.locTitle.setJson({
      default: "Mobile Number",
      es: "Teléfono",
      fr: "Téléphone",
    });

    options.question.descriptionLocation = "underInput";
    options.question.locDescription.setJson({
      default: "Standard message and data rates may apply.",
      es: "Pueden aplicar las tarifas normales para mensajes de texto y datos.",
      fr: "Les tarifs standard pour les messages et les données peuvent s'appliquer.",
    });
  }
};
