import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Serializer,
  surveyLocalization,
} from "survey-core";
import {
  DefaultFonts,
  editorLocalization,
  QuestionAddedEvent,
  SurveyCreatorModel,
} from "survey-creator-core";
import registerIcons from "./fontAwesomeIcons";

import { ICustomQuestionTypeConfigurationVOI } from "../interfaces";
import { initThemeLincoln } from "../themes/surveyJS/lincoln";

registerIcons(["cars"]);

export const initSurveyLincoln = () => {
  surveyLocalization.supportedLocales = ["en", "es", "fr"];

  Serializer.addProperty("question", {
    name: "_ffs",
    displayName: "FFS question",
    type: "text",
    category: "data",
  });

  ComponentCollection.Instance.add({
    name: "lincolnvoi",
    title: "Lincoln VOI",
    iconName: "icon-cars",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.addProperty("lincolnvoi", {
        name: "onlyInclude",
        displayName: "Only Include these vehicle_ids",
        type: "text",
        category: "general",
      });
    },
    questionJSON: {
      type: "checkbox",
      name: "voi",
      renderAs: "voi",
      maxSelectedChoices: 3,
      choicesByUrl: {
        url: "https://cdn.latitudewebservices.com/vehicles/lincoln.json",
        valueName: "id",
        titleName: "name",
        image: "image",
      },
    },
    onLoaded(question) {
      this.updateOnlyInclude(question);
    },
    onPropertyChanged(question, propertyName, newValue) {
      if (propertyName === "onlyInclude") {
        this.updateOnlyInclude(question);
      }
    },
    updateOnlyInclude(question) {
      const checkbox = question.contentQuestion;
      if (!!checkbox) {
        checkbox.onlyInclude = question.onlyInclude;
      }
    },
  } as ICustomQuestionTypeConfigurationVOI);

  ComponentCollection.Instance.add({
    name: "lincolnoptin",
    title: "Lincoln Opt-In",
    iconName: "icon-thumbs-up",
    showInToolbox: true,
    inheritBaseProps: true,
    onInit: () => {
      Serializer.getProperty("lincolnoptin", "name").readOnly = true;
      Serializer.getProperty("lincolnoptin", "_ffs").readOnly = true;
    },
    questionJSON: {
      type: "radiogroup",
      renderAs: "radiobuttongroup",
      buttonSize: "medium",
      name: "email_opt_in",
      isRequired: true,
      choices: [
        {
          value: 1,
          text: {
            default: "Yes",
            es: "Sí",
            fr: "Oui",
          },
        },
        {
          value: 0,
          text: {
            default: "No",
            es: "No",
            fr: "Non",
          },
        },
      ],
    },
  } as ICustomQuestionTypeConfiguration);

  DefaultFonts.unshift("ProximaNovaRgRegular", "lincolnmillerbblack");
};

export const initCreatorLincoln = (creator: SurveyCreatorModel) => {
  initThemeLincoln(creator);

  const enLocale = editorLocalization.getLocale("en");
  enLocale.toolboxCategories["__lincolnCagegory"] = "Lincoln Questions";

  creator.toolbox.changeCategories([
    { name: "lincolnvoi", category: "__lincolnCagegory" },
    { name: "lincolnoptin", category: "__lincolnCagegory" },
  ]);
};

export const prepareCreatorOnQuestionAddedLincoln = (
  sender: SurveyCreatorModel,
  options: QuestionAddedEvent
) => {
  if (options.question.getType() === "lincolnvoi") {
    console.log("lincolnvoi question added");

    options.question.name = "lincolnVOI";
    options.question._ffs = "voi";

    options.question.locTitle.setJson({
      default:
        "I am interested in receiving more information on the following vehicles.",
      es: "Me interesaría recibir más información sobre los siguientes vehículos.",
      fr: "Je suis intéressé à recevoir plus d'informations sur les véhicules suivants.",
    });

    options.question.locDescription.setJson({
      default: "You may select up to three models.",
      es: "Puede seleccionar hasta tres modelos.",
      fr: "Vous pouvez sélectionner jusqu'à trois modèles.",
    });
  }

  if (options.question.getType() === "lincolnoptin") {
    console.log("lincolnoptin question added");
    options.question.name = "lincolnEmailOptIn";
    options.question._ffs = "emailOptIn";
    options.question.isRequired = true;

    options.question.locTitle.setJson({
      default:
        "Please email me communications, including product and service information, surveys and special offers from Lincoln and its retailers.",
      es: "Por favor, envíenme comunicaciones, incluyendo información sobre productos y servicios, encuestas y ofertas especiales de Lincoln y sus minoristas.",
      fr: "",
    });

    options.question.descriptionLocation = "underInput";
    options.question.locDescription.setJson({
      default:
        "Lincoln respects your privacy and treats your personal information with care. [Click here to read Lincoln's privacy policy.](https://lincoln.com/help/privacy/)",
      es: "Lincoln respeta su confidencialidad y trata su información personal con respeto. [Haga clic aquí para consultar la política de confidencialidad de Lincoln.](https://es.lincoln.com/help/privacy/)",
      fr: "Lincoln respecte votre vie privée et traite vos informations personnelles avec soin. [Cliquez ici pour lire la politique de confidentialité de Lincoln.](https://lincoln.com/help/privacy/)",
    });
  }
};
