import { editorLocalization, SurveyCreatorModel } from "survey-creator-core";
import { IExtendedTheme } from "../../interfaces";

export const themeJSON: IExtendedTheme = {
  themeName: "__lincoln",
  favicon: "https://cdn.latitudewebservices.com/logos/Lincoln_favicon.png",
  colorPalette: "light",
  isPanelless: false,
  headerView: "advanced",
  header: {
      height: 565,
      mobileHeight: 200,
      inheritWidthFrom: "container",
      textAreaWidth: 600,
      overlapEnabled: false,
      backgroundImage: "https://cdn.latitudewebservices.com/headers/images/2024_NautilusTourRSVP_v2.jpg",
      backgroundImageOpacity: 1,
      backgroundImageFit: "cover",
      logoPositionX: "left",
      logoPositionY: "top",
      titlePositionX: "center",
      titlePositionY: "top",
      descriptionPositionX: "center",
      descriptionPositionY: "top"
  },
  cssVariables: {
      "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
      "--sjs-general-backcolor-dark": "rgba(228, 228, 228, 1)",
      "--sjs-general-backcolor-dim": "#FFFFFF",
      "--sjs-general-backcolor-dim-light": "rgba(238, 238, 238, 1)",
      "--sjs-general-backcolor-dim-dark": "rgba(220, 220, 220, 1)",
      "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.6)",
      "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.6)",
      "--sjs-primary-backcolor": "#000000",
      "--sjs-primary-backcolor-light": "rgba(228, 228, 228, 1)",
      "--sjs-primary-backcolor-dark": "rgba(0, 0, 0, 1)",
      "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-base-unit": "8px",
      "--sjs-corner-radius": "0px",
      "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
      "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
      "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.2)",
      "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.2)",
      "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.25)",
      "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.25)",
      "--sjs-border-light": "rgba(0, 0, 0, 0.25)",
      "--sjs-border-default": "rgba(0, 0, 0, 0.25)",
      "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
      "--sjs-special-red": "rgba(229, 10, 62, 1)",
      "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
      "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-green": "rgba(25, 179, 148, 1)",
      "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
      "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-blue": "rgba(67, 127, 217, 1)",
      "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
      "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
      "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-article-font-xx-large-textDecoration": "none",
      "--sjs-article-font-xx-large-fontWeight": "700",
      "--sjs-article-font-xx-large-fontStyle": "normal",
      "--sjs-article-font-xx-large-fontStretch": "normal",
      "--sjs-article-font-xx-large-letterSpacing": "0",
      "--sjs-article-font-xx-large-lineHeight": "64px",
      "--sjs-article-font-xx-large-paragraphIndent": "0px",
      "--sjs-article-font-xx-large-textCase": "none",
      "--sjs-article-font-x-large-textDecoration": "none",
      "--sjs-article-font-x-large-fontWeight": "700",
      "--sjs-article-font-x-large-fontStyle": "normal",
      "--sjs-article-font-x-large-fontStretch": "normal",
      "--sjs-article-font-x-large-letterSpacing": "0",
      "--sjs-article-font-x-large-lineHeight": "56px",
      "--sjs-article-font-x-large-paragraphIndent": "0px",
      "--sjs-article-font-x-large-textCase": "none",
      "--sjs-article-font-large-textDecoration": "none",
      "--sjs-article-font-large-fontWeight": "700",
      "--sjs-article-font-large-fontStyle": "normal",
      "--sjs-article-font-large-fontStretch": "normal",
      "--sjs-article-font-large-letterSpacing": "0",
      "--sjs-article-font-large-lineHeight": "40px",
      "--sjs-article-font-large-paragraphIndent": "0px",
      "--sjs-article-font-large-textCase": "none",
      "--sjs-article-font-medium-textDecoration": "none",
      "--sjs-article-font-medium-fontWeight": "700",
      "--sjs-article-font-medium-fontStyle": "normal",
      "--sjs-article-font-medium-fontStretch": "normal",
      "--sjs-article-font-medium-letterSpacing": "0",
      "--sjs-article-font-medium-lineHeight": "32px",
      "--sjs-article-font-medium-paragraphIndent": "0px",
      "--sjs-article-font-medium-textCase": "none",
      "--sjs-article-font-default-textDecoration": "none",
      "--sjs-article-font-default-fontWeight": "400",
      "--sjs-article-font-default-fontStyle": "normal",
      "--sjs-article-font-default-fontStretch": "normal",
      "--sjs-article-font-default-letterSpacing": "0",
      "--sjs-article-font-default-lineHeight": "28px",
      "--sjs-article-font-default-paragraphIndent": "0px",
      "--sjs-article-font-default-textCase": "none",
      "--sjs-font-size": "16px",
      "--sjs-question-background": "rgba(255, 255, 255, 0.05)",
      "--sjs-header-backcolor": "#ffffff",
      "--sjs-font-headerdescription-color": "rgba(255, 255, 255, 1)",
      "--sjs-font-headertitle-color": "rgba(255, 255, 255, 1)",
      "--sjs-font-family": "ProximaNovaRgRegular, arial, sans-serif",
      "--sjs-font-questiontitle-family": "ProximaNovaSBold, arial, sans-serif",
      "--sjs-font-editorfont-family": "ProximaNovaRgRegular, arial, sans-serif",
      "--sjs-font-headertitle-family": "lincolnmillerbblack, \"Times New Roman\", Times, serif",
      "--sjs-font-editorfont-placeholdercolor": "rgba(0, 0, 0, 0.6)"
  }
};

export const initThemeLincoln = (creator: SurveyCreatorModel) => {
  const themeTabPlugin = creator.themeEditor;
  themeTabPlugin.addTheme(themeJSON);

  const enLocale = editorLocalization.getLocale("en");
  enLocale.theme.names['__lincoln'] = "Lincoln Theme";
};
